import React, { useEffect, useState } from 'react';
import './DataTableStagesList.scss';
import BootstrapTable, {
    TableChangeState,
    TableChangeType,
} from '../../../components/react-bootstrap-table-next/react-bootstrap-table2';
import moment from 'moment';
import SortingOption from '../../../components/Table/models/bootstrap-table-sorting-option';
import {
    DataRequestProject,
    DataRequestProjectState,
} from '../../../models/DataRequestHub/DataRequestProject';
import { Button } from 'reactstrap';
import {
    DataTable,
    DataTableAndTableViewFlatRow,
    DataTableView,
    TableRowType,
} from '../../../models/DataRequestHub/DataTable';
import CreateDataTablePopup from '../CreateDataTablePopup/CreateDataTablePopup';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faWrench } from '@fortawesome/free-solid-svg-icons';
import Helpers from '../../../utils/helper';
import EditableDateLabel from '../../../components/EditableDateLabel/EditableDateLabel';
import { isDateInvalid } from '../../../components/EditableTable/EditableTableValidationHelper';
import createNotification from '../../../utils/createNotification';
import { useStateSelector } from '../../../store/selectors';
import { faPlusSquare } from '@fortawesome/free-regular-svg-icons';
import { DesignModeSwitcher } from '../DesignModeSwitcher/DesignModeSwitcher';
import { useNavigate } from 'react-router-dom';
import { EditorTypeEnum } from '../../Admin/DataRequest/ProjectEditorHost/ProjectEditorHost';
import { DataFormStageNameEditor } from '../QuestionnaireStagesList/QuestionnaireStageNameEditor/DataFormStageNameEditor';

interface DataTableStagesListProps {
    projects: DataRequestProject[];
    isAdminView: boolean;
    isEditorMode: boolean;
    invalidTableStageIds: number[];
    invalidTableViewIds: number[];
    updateProjectsData(isInitialFetch?: boolean): void;
    isTemplateEditorView: boolean;
    openSettings?(): void;
    onAddNewDataTableViewClick?(tableId: number): void;
    onTableViewNameClick?(tableId: number, viewId: number): void;
    onTableNameClick?(tableId: number): void;
    enableDesignMode?(tableId: number): void;
}

const DataTableStagesList = (props: DataTableStagesListProps) => {
    const navigate = useNavigate();
    const axios = useStateSelector((s) => s.core.axios);
    const [tableData, setTableData] = useState<DataTableAndTableViewFlatRow[]>(
        []
    );
    const [sortingOption, setSortingOption] = useState<SortingOption>({
        dataField: 'name',
        order: 'asc',
    });
    const [isCreateDataTablePopupVisible, setIsCreateDataTablePopupVisible] =
        useState(false);

    const getProjectId = (tableId: number) => {
        const projectId = props.projects.find((project) =>
            project.dataTables.some((table) => table.id === tableId)
        ).id;

        return projectId;
    };

    const handleDueDateChange = (
        date: Date,
        tableId: number,
        viewId: number
    ) => {
        if (isDateInvalid(date)) {
            date = new Date('0001-01-01');
        }

        const params = {
            dueDate: date,
        };

        const projectId = getProjectId(tableId);
        axios
            .put(
                `/api/dataRequestProjects/${projectId}/dataTables/${tableId}/dataTableViews/${viewId}/dueDate`,
                params
            )
            .then((response) => {
                if (response.status === 200) {
                    props.updateProjectsData();
                } else {
                    const message = (response as any)?.response?.data?.detail;
                    createNotification(
                        message ?? 'An error occured while updating stage',
                        'error'
                    );
                }
            });
    };

    const getColumns = () => [
        { dataField: 'id', text: '', hidden: true },
        {
            dataField: 'practiceName',
            text: 'Practice Name',
            hidden: props.projects?.length <= 1,
            sort: true,
            formatter: (
                cellContent: string,
                row: DataTableAndTableViewFlatRow
            ) => (row.rowType === TableRowType.DataTable ? cellContent : ''),
        },
        {
            dataField: 'name',
            text: 'Table Views',
            sort: true,
            headerClasses: 'stage-name-header',
            formatter: (
                cellContent: string,
                row: DataTableAndTableViewFlatRow
            ) => {
                const projectId = getProjectId(row.tableId);
                const updateUrl =
                    row.rowType === TableRowType.DataTable
                        ? `/api/DataRequestProjects/${projectId}/dataTables/${row.tableId}/name`
                        : `/api/DataRequestProjects/${projectId}/dataTables/${row.tableId}/dataTableViews/${row.viewId}/name`;

                return (
                    <div className="data-table-name-wrapper">
                        <DataFormStageNameEditor
                            isNameClickable={
                                props.isAdminView ||
                                row.rowType === TableRowType.DataTableView
                            }
                            updateUrl={updateUrl}
                            onSuccessfulUpdate={() => {
                                props.updateProjectsData();
                            }}
                            stageName={cellContent}
                            onStageNameClick={() => {
                                row.rowType === TableRowType.DataTableView
                                    ? props.onTableViewNameClick(
                                          row.tableId,
                                          row.viewId
                                      )
                                    : props.onTableNameClick(row.tableId);
                            }}
                            isTemplateEditorView={props.isEditorMode}
                            isEditable={props.isAdminView && isProjectActive()}
                            enableLinkText={
                                props.isAdminView ||
                                row.rowType === TableRowType.DataTableView
                            }
                        />
                    </div>
                );
            },
        },
        {
            dataField: 'dateCreated',
            text: 'Date Created',
            sort: true,
            formatter: (cellContent: Date, row: DataTableAndTableViewFlatRow) =>
                row.rowType === TableRowType.DataTableView
                    ? moment(cellContent).format('L')
                    : '',
        },
        {
            dataField: 'dueDate',
            text: 'Due Date',
            sort: true,
            formatter: (
                cellContent: Date,
                row: DataTableAndTableViewFlatRow
            ) => {
                return row.rowType === TableRowType.DataTableView ? (
                    <EditableDateLabel
                        value={cellContent}
                        isEditable={props.isAdminView}
                        onChange={(value) =>
                            handleDueDateChange(
                                new Date(value),
                                row.tableId,
                                row.viewId
                            )
                        }
                    />
                ) : (
                    <></>
                );
            },
        },
        {
            dataField: 'dateComplete',
            text: 'Date Complete',
            sort: true,
            formatter: (cellContent: Date) =>
                cellContent ? moment(cellContent).format('L') : 'NA',
            hidden: true,
        },
        {
            dataField: 'id',
            text: 'Action',
            sort: false,
            formatter: (_: number, row: DataTableAndTableViewFlatRow) => {
                return row.rowType === TableRowType.DataTable ? (
                    <div className="action-icons">
                        <FontAwesomeIcon
                            onClick={() =>
                                props.onAddNewDataTableViewClick(row.tableId)
                            }
                            icon={faPlusSquare as any}
                        />
                    </div>
                ) : (
                    <></>
                );
            },
            hidden: !props.isTemplateEditorView || !props.isAdminView,
        },
    ];

    const sortDataTables = (newSortingOption: SortingOption): DataTable[] => {
        const dataTables = getDataTables();

        const tablesWithSortedViews = dataTables.map((table): DataTable => {
            const noDueDateViews = table.views?.filter(
                (f) => f.dueDate === null
            );
            const viewsWithDueDate = table.views?.filter(
                (f) => f.dueDate !== null
            );
            const sortedViews = Helpers.dynamicSorting(
                viewsWithDueDate,
                newSortingOption.dataField,
                newSortingOption.order
            );

            return {
                ...table,
                views: [...sortedViews, ...noDueDateViews],
            };
        });

        switch (newSortingOption.dataField) {
            case 'dueDate':
                const dueDateTables = tablesWithSortedViews.filter((f) =>
                    f.views?.every((e) => e.dueDate === null)
                );

                const tableWithViews = tablesWithSortedViews.filter((f) =>
                    f.views?.some((e) => e.dueDate)
                );

                const result = sortDataTablesByDueDate(
                    tableWithViews,
                    sortingOption.order
                );

                return [...result, ...dueDateTables];

            default:
                let sortedDataTables: DataTable[] = Helpers.dynamicSorting(
                    tablesWithSortedViews,
                    newSortingOption.dataField,
                    newSortingOption.order
                );
                return sortedDataTables;
        }
    };

    const sortDataTablesByDueDate = (
        dataTables: DataTable[],
        order: 'asc' | 'desc'
    ) => {
        const result = dataTables.sort((a, b) => {
            const directionScale = order === 'asc' ? 1 : -1;

            const aNotEmptyDates = a.views
                .filter((f) => f.dueDate !== null)
                .map((m) => new Date(m.dueDate).getTime());

            const aDueDate = aNotEmptyDates.reduce(
                (max, current) => (current > max ? current : max),
                aNotEmptyDates[0]
            );

            const bNotEmptyDates = b.views
                .filter((f) => f.dueDate !== null)
                .map((m) => new Date(m.dueDate).getTime());

            const bDueDate = bNotEmptyDates.reduce(
                (max, current) => (current > max ? current : max),
                bNotEmptyDates[0]
            );

            if (aDueDate === bDueDate) {
                return 0;
            }

            return aDueDate > bDueDate ? directionScale : -directionScale;
        });

        return result;
    };

    useEffect(() => {
        const sortedDataTables = sortDataTables(sortingOption);
        const tableRows = sortedDataTables.flatMap(
            (table: DataTable): DataTableAndTableViewFlatRow[] => {
                const parentTable: DataTableAndTableViewFlatRow = {
                    rowId: `Table-${table.id}`,
                    tableId: table.id,
                    viewId: 0,
                    name: table.name,
                    dateCreated: table.dateCreated,
                    rowType: TableRowType.DataTable,
                    practiceName: table.practiceName,
                };

                const viewsList = table.views.map(
                    (view: DataTableView): DataTableAndTableViewFlatRow => {
                        const result: DataTableAndTableViewFlatRow = {
                            rowId: `View-${view.id}`,
                            tableId: table.id,
                            viewId: view.id,
                            name: view.name,
                            dateCreated: view.dateCreated,
                            description: view.description,
                            rowType: TableRowType.DataTableView,
                            dueDate: view.dueDate,
                        };
                        return result;
                    }
                );
                return [parentTable, ...viewsList];
            }
        );

        setTableData(tableRows);
    }, [props.projects, sortingOption]);

    const getProject = () => {
        return props.projects.find((f) => f);
    };

    const getDataTables = () =>
        props.projects.flatMap((project) =>
            project.dataTables.map((table) => ({
                ...table,
                practiceName: project.practiceName,
            }))
        );

    const handleTableChange = (
        _type: TableChangeType,
        newState: TableChangeState<any>
    ) => {
        const newSortingOption: SortingOption = {
            dataField: newState.sortField,
            order: newState.sortOrder,
        };

        setSortingOption(newSortingOption);
    };

    const isProjectActive = () => {
        const targetProject = props.projects.find((project) => project);
        return targetProject.state === DataRequestProjectState.Active;
    };

    return (
        <div className="data-table-stages-list">
            <div className="editor-header">
                {props.isAdminView ? (
                    <div className="data-table-stages-host-header">
                        <Button
                            className="btn btn-primary"
                            onClick={() => {
                                setIsCreateDataTablePopupVisible(true);
                            }}>
                            Create Additional Table
                        </Button>
                        <div className="project-settings-section">
                            {props.isTemplateEditorView && props.isAdminView ? (
                                <div
                                    className="clickable"
                                    onClick={() => {
                                        props.openSettings();
                                    }}>
                                    <FontAwesomeIcon icon={faWrench} />
                                    {'User View Settings'}
                                </div>
                            ) : (
                                <></>
                            )}
                            {isProjectActive() && (
                                <DesignModeSwitcher
                                    defaultValue={
                                        props.isEditorMode ? true : false
                                    }
                                    onEnable={props.enableDesignMode}
                                    onDisable={() => {
                                        navigate(
                                            `/data-request-dashboard?tab=${
                                                EditorTypeEnum.DataTable + 1
                                            }`,
                                            {
                                                state: {
                                                    projectId: getProject().id,
                                                    isAdmin: true,
                                                    projectName:
                                                        getProject().name,
                                                    practiceName:
                                                        getProject()
                                                            .practiceName,
                                                },
                                            }
                                        );
                                    }}
                                    formId={0}></DesignModeSwitcher>
                            )}
                        </div>
                    </div>
                ) : (
                    []
                )}
            </div>

            <BootstrapTable
                striped
                bordered
                bootstrap4
                remote
                keyField="rowId"
                defaultSorted={[sortingOption]}
                data={tableData}
                columns={getColumns()}
                rowClasses={(row: DataTableAndTableViewFlatRow) => {
                    if (row.rowType === TableRowType.DataTable) {
                        const invalidClasses =
                            props.invalidTableStageIds.includes(row.tableId)
                                ? 'invalid-stage'
                                : '';
                        return `data-table-row ${invalidClasses}`;
                    } else {
                        const invalidClasses =
                            props.invalidTableViewIds.includes(row.viewId)
                                ? 'invalid-stage'
                                : '';
                        return `table-view-row ${invalidClasses}`;
                    }
                }}
                onTableChange={handleTableChange}
                noDataIndication="No Data available"
            />

            {isCreateDataTablePopupVisible && props.isAdminView ? (
                <CreateDataTablePopup
                    project={getProject()}
                    onClose={() => setIsCreateDataTablePopupVisible(false)}
                    onAdd={props.updateProjectsData}
                />
            ) : (
                []
            )}
        </div>
    );
};

export default DataTableStagesList;
